import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import {
    DataTable,
} from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { CSVLink } from "react-csv";

// Column
import { PackagingColumns, viewPackingColumns } from '../../appconfig/DatatableSetting';

// Dropdown
import { thirumDropdown, polymerDropdown, sdmDropdown, gauchoDropdown, metalyxilDropdown, chemicalDropdown, outerBagDropdown } from '../../appconfig/Settings';

// Data table
import {
    defaultPageRowOptions,
    defaultRowOptions,
    paginatorLinks,
    showingEntries,
    SkeletonbodyTemplate,
    Skeletonitems,
} from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';
import { PackingValidate } from '../../config/Validate';
import { Loader } from '../../components/Loader/Loader';

export const Packaging = () => {
    document.title = 'Packaging | Swarnim Farms';

    //Product Crud Object
    const [products, setProducts] = useState<any>([]);

    const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const [csvData, setCsvData] = useState([]);

    // Error object
    const [isError, setIsError] = useState<any>(false);
    const [errors, setErrors] = useState<any>({});

    // Date Object
    let today = new Date();
    const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

    //Set Toast/ Filter Properties
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const toast = useRef<any>(null);

    const [viewProductDialog, setViewProductDialog] =
        useState<boolean>(false);
    const [viewProductData, setViewProductData] = useState<any>({});

    const [spinner, setSpinner] = useState<boolean>(false);


    const [packingDialog, setPackingDialog] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>({});
    const [packingData, setPackingData] = useState<any>({});

    //Loading/Page Loading
    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isCropArrayUpdate, setIsCropArrayUpdate] = useState(false);
    const [weightDropdown, setWeightDropdown] = useState([]);
    const [csvLoading, setCsvLoading] = useState<boolean>(false);

    const [chemicalData, setChemicalData] = useState<any>(null);
    const [chemicalWtData, setChemicalWtData] = useState<any>(null);
    const [chemicalWtDropdown, setChemicalWtDropdown] = useState<any>([]);

    const [outerBag, setOuterBag] = useState<any>({name: 'COLOUR STANDARD BAG', code: 'colour_std_bag' });

    const [packingDetailArray, setPackingDetailArray] = useState<any>([
        {
            crop_detail_id: '',
            no_of_packets: '',
            damage: '',
            net_packets: ''
        },
    ]);

    // Page service
    const pageService = new PageService();

    // use effect method
    useEffect(() => {
        getData();
    }, [dates]);

    useEffect(() => {
        if (isCropArrayUpdate) {
            setIsCropArrayUpdate(false);
            getPackingDetailArrayUI();
        }
    }, [isCropArrayUpdate]);

    useEffect(() => {
        getPackingDetailArrayUI();
    }, [packingDetailArray]);

    useEffect(() => {
        if (csvData.length > 0) {
            csvLink?.current?.link.click();
            setCsvData([]);
        }
    }, [csvData]);

    // get raw matrail product data
    const getData = async () => {
        setLoading(true);
        setErrors(false);
        setIsError(false);

        // Api call
        pageService
            .packinglist()
            .then((response) => {
                // Get response
                if (response) {
                    const productData = response.data;
                    if (productData.length == 0) {
                        setProducts([]);
                        setErrors(false);
                        setLoading(false);
                        setPageLoad(true);
                    } else {
                        setProducts(productData);
                        setErrors(false);
                        setLoading(false);
                        setPageLoad(true);
                    }
                } else {
                    setProducts([]);
                    setErrors(false);
                    setLoading(false);
                    setPageLoad(false);
                }
            });
    };

    const getcropById = async (crop_id: any, variety_id: any) => {

        pageService.getCropsPackingSizeById(crop_id, variety_id).then((response) => {
            // get response
            if (response) {
                const DataList = response;
                if (DataList.length == 0) {
                    setWeightDropdown([]);
                } else {
                    if (response) {
                        const DataList = response;
                        let tempWeightArray: any = [];
                        for (let i = 0; i < DataList.length; i++) {
                            const cropObj: any = {};
                            cropObj['name'] = DataList[i].weight;
                            cropObj['code'] = DataList[i].id;
                            tempWeightArray.push(cropObj);
                        }
                        setWeightDropdown(tempWeightArray);
                    }
                }
                setPageLoad(true);
            } else {
                setPageLoad(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, Please try again.',
                });
            }
        });
    }

    // on date change
    const onDateChange = (e: any) => {
        setDates(e.value);
        getData();
    };

    //onChange proccessing data
    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || '';
        setPackingData({ ...packingData, [name]: val });
    };

    //onChange proccessing data
    const onChemicalChange = (e: any) => {
        setChemicalData(e);
        if (e.code == 'THIRUM') {
            setChemicalWtDropdown(thirumDropdown)
        } else if (e.code == 'SDM') {
            setChemicalWtDropdown(sdmDropdown)
        } else if (e.code == 'POLYMER') {
            setChemicalWtDropdown(polymerDropdown)
        } else if (e.code == 'GAUCHO') {
            setChemicalWtDropdown(gauchoDropdown)
        } else if (e.code == 'METALYXIL') {
            setChemicalWtDropdown(metalyxilDropdown)
        }
    };

    const addPackingData = (rowData: any) => {
        setRowData(rowData);
        setPackingDialog(true);
        setViewProductData(rowData);
        getcropById(rowData.raw_material.crop_obj.id, rowData.raw_material.variety_obj.id);
        setIsCropArrayUpdate(true);
    }

    const viewRawMaterailData = (rowData: any) => {
        setSpinner(true);

        pageService.getPackingDataById(rowData.id).then((response) => {
          
            // get response
            if (response) {
                setViewProductDialog(true);
                setViewProductData(response);
                setSpinner(false);
                setPageLoad(true);
            } else {
                setPageLoad(false);
                getData();
                setSpinner(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'No data found',
                });
            }
        });
    }

    // close view orders data dialog
    const hideProductDialog = () => {
        setViewProductDialog(false);
        setViewProductData([]);
        setSpinner(false);

    };

    const onSubmit = () => {
        const { errors, isError } = PackingValidate(packingData, packingDetailArray);
        setErrors(errors);
        setIsError(isError);

        //Check Error if no errors then call API
        if (!isError) {
            setLoading(true);

            //Request object
            let formRequestData = new FormData();
            formRequestData.append('remaining_qnty', viewProductData?.remaining_qnty);
            formRequestData.append('crop_id', rowData["raw_material"]["crop_obj"]["id"]);
            formRequestData.append('raw_id', rowData["raw_id"]);
            formRequestData.append('processed_id', rowData["id"]);
            formRequestData.append('losses', packingData["losses"]);
            packingData["quantity"] = (packingData["packed_qnty"]) - packingData["losses"];
            formRequestData.append('quantity', packingData["quantity"]);
            formRequestData.append('packed_qnty', packingData["packed_qnty"]);
            packingData["weight_in"] = viewProductData?.weight_in;
            formRequestData.append('weight_in', packingData["weight_in"]);
            formRequestData.append('label_from', packingData["label_from"]);
            formRequestData.append('label_to', packingData["label_to"]);
            if (!window.cn(packingData) && packingData["missing_label"]) {
                formRequestData.append('missing_label', packingData["missing_label"]);
            }
            formRequestData.append('packing_details', JSON.stringify(packingDetailArray));
            if (!window.cn(packingData) && packingData["chemical_quantity"]) {
                formRequestData.append('chemical_quantity', packingData["chemical_quantity"]);
            }
            formRequestData.append('chemical_unit', chemicalWtData?.code);
            formRequestData.append('chemical', chemicalData?.code);
            formRequestData.append('outer_bag', outerBag?.code);
            
            // api call
            pageService.addPackingData(formRequestData).then((result) => {
                setPackingDialog(false);
                setPackingDetailArray([
                    {
                        crop_detail_id: '',
                        no_of_packets: '',
                        damage: '',
                        net_packets: ''
                    }
                ]);
                setPackingData({});
                setChemicalData(null);
                setChemicalWtData(null);
                setOuterBag({name: 'COLOUR STANDARD BAG', code: 'colour_std_bag' })
                setErrors(false);
                setLoading(false);
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: result.message,
                });
                getData();
            }).catch(error => {
                setErrors(false);
                setLoading(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.response.data.error,
                });
            }
            );
        }
    }

    // onHandleChange for crops
    const onHandleChangeCrop = (e: any, name: string, index: number) => {
        let tempPackingArray = packingDetailArray;
        if (name == "crop_detail_id") {
            tempPackingArray[index][name] = e;
        } else {
            tempPackingArray[index][name] = e.target.value;
            tempPackingArray[index]["net_packets"] = '';

            if (tempPackingArray[index]["no_of_packets"] && tempPackingArray[index]["damage"]) {
                tempPackingArray[index]["net_packets"] = parseInt(tempPackingArray[index]["no_of_packets"]) -
                    parseInt(tempPackingArray[index]["damage"]);
            }
        }
        setPackingDetailArray(tempPackingArray);
        setIsCropArrayUpdate(true);
    };

    // Handle crops Data
    const handlePackingDetailArray = () => {
        const defaultObject = {
            crop_detail_id: '',
            no_of_packets: '',
            damage: '',
            net_packets: ''
        };
        let array = packingDetailArray;
        array.push(defaultObject);
        setPackingDetailArray(array);
        setIsCropArrayUpdate(true);
    };

    const getPackingDetailArrayUI = () => {
        return (
            <>
                {packingDetailArray.map((item: any, index: number) => (
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="name">Packing size *</label>
                            <Dropdown
                                filter
                                value={!window.cn(item.crop_detail_id) ? item.crop_detail_id : ""}
                                onChange={(e) => onHandleChangeCrop(e.value, 'crop_detail_id', index)}
                                options={weightDropdown}
                                optionLabel="name"
                                name="crop_detail_id"
                                className={errors['crop_detail_id'] && 'p-invalid'}
                                placeholder="Select Variety"
                            ></Dropdown>
                            <small className="p-invalid-txt">{errors['crop_detail_id']}</small>
                        </div>
                        <div className="field col">
                            <label htmlFor="no_of_packets">No. Of Packets Used *</label>
                            <InputText
                                 keyfilter="num"
                                value={!window.cn(item.no_of_packets) ? item.no_of_packets : ""}
                                name="no_of_packets"
                                autoComplete="off"
                                onChange={(e) => onHandleChangeCrop(e, 'no_of_packets', index)}
                                className={errors['no_of_packets'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['no_of_packets']}</small>
                        </div>
                        <div className="field col">
                            <label htmlFor="damage">Damage Packets *</label>
                            <InputText
                                 keyfilter="num"
                                value={!window.cn(item.damage) ? item.damage : ""}
                                name="damage"
                                autoComplete="off"
                                onChange={(e) => onHandleChangeCrop(e, 'damage', index)}
                                className={errors['damage'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['damage']}</small>
                        </div>
                        <div className="field col">
                            <label htmlFor="net_packets">Net packets</label>
                            <InputText
                                 keyfilter="num"
                                value={!window.cn(item.net_packets) ? item.net_packets : ""}
                                name="net_packets"
                                readOnly
                                disabled
                            />
                        </div>
                        {index > 0 ? (
                            <>
                                <div className='flex' style={{ alignItems: "center" }}>

                                    <Button
                                        icon="pi pi-trash"
                                        className="p-button-rounded p-button-danger mt-2"
                                        onClick={() => deleteCrop(index)}
                                    />
                                </div>
                            </>
                        ) : (
                            <div></div>
                        )}
                    </div>
                ))}
            </>
        );
    };

    // Delete crop
    const deleteCrop = (index: number) => {
        let deleteCropArray = packingDetailArray;
        deleteCropArray.splice(index, 1);
        setPackingDetailArray(deleteCropArray);
        setIsCropArrayUpdate(true);
    };

    const hidePackingDailog = () => {
        setPackingDialog(false);
        setPackingDetailArray([
            {
                crop_detail_id: '',
                no_of_packets: '',
                damage: '',
                net_packets: ''
            },
        ]);
        setPackingData({});
        setErrors(false);
        setViewProductData({});
        setChemicalData(null);
        setChemicalWtData(null);
        setOuterBag({name: 'COLOUR STANDARD BAG', code: 'colour_std_bag' })
    };

    // for column action
    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-primary mt-2 mb-2 mr-3"
                    tooltip='Add'
                    tooltipOptions={{ position: 'bottom' }}
                    onClick={() => addPackingData(rowData)}
                    disabled={rowData["packing_status"] == 1 ? true : false}
                />

                <Button
                    icon="pi pi-eye"
                    tooltip='View'
                    tooltipOptions={{ position: 'bottom' }}
                    className="p-button-rounded p-button-primary mt-2 mb-2"
                    onClick={() => viewRawMaterailData(rowData)}
                />
            </div>
        );
    };

    //download csv
    const exportCSV = async () => {
        setCsvLoading(true);
        //Validation Chec
        pageService
            .packinglist()
            .then((response) => {
                // get response
                if (response) {
                    let csvDetails = response.csv_data;
                    if (csvDetails.length > 0) {
                        setCsvData(csvDetails);
                        setCsvLoading(false);
                    }
                } else {
                    setCsvData([]);
                }
            });
    }

    const cropBodyTemplate = (rowData: any) => {
        return (
            <>
                <div>{rowData.raw_material.crop_obj?.name}</div>
            </>
        )
    };

    const lotNoBodyTemplate = (rowData: any) => {
        return (
            <>
                <div>{rowData.raw_material?.lot_no}</div>
            </>
        )
    };

    const cropDivisionBodyTemplate = (rowData: any) => {
        return (
            <>
                <div>{rowData.raw_material?.crop_division}</div>
            </>
        )
    };

    // for left toolbar
    const leftToolbarTemplate = () => {
        return (
            <>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) => setGlobalFilter(e.currentTarget.value)}
                        placeholder="Search..."
                    />
                </span>
            </>
        );
    };

    // for right toolbar
    const rightToolbarTemplate = () => {
        return (
            <>
                {/* <Calendar
                    value={dates}
                    dateFormat="dd/mm/yy"
                    onChange={(e) => onDateChange(e)}
                    selectionMode="range"
                    showIcon
                /> */}
                <Button
                    label="Export"
                    icon="pi pi-file-export"
                    className="p-button-success mr-2 ml-2"
                    loading={csvLoading}
                    onClick={exportCSV} />
                <CSVLink
                    data={csvData}
                    filename='Packaging_list.csv'
                    className='hidden'
                    ref={csvLink}
                    target='_blank'
                />
            </>
        );
    };

    const bagWeightTemplate = (rowData: any) => {
        return (
            <>
                <div>{rowData.bag_weight}</div>
            </>
        );
    }

    const noOfPacketsTemplate = (rowData: any) => {
        return (
            <>
                <div>{rowData.no_of_packets}</div>
            </>
        );
    }

    return (
        <>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <h4>Packaging List</h4>
                        <Toast ref={toast} />
                        <Toolbar
                            className="mb-4"
                            left={leftToolbarTemplate}
                            right={rightToolbarTemplate}
                        ></Toolbar>

                        {/* Datatable Start */}
                        {pageLoad ? (
                            <>
                                <DataTable
                                    value={products}
                                    loading={loading}
                                    paginator={products.length > 0 && true}
                                    globalFilter={globalFilter}
                                    rows={defaultRowOptions}
                                    rowsPerPageOptions={defaultPageRowOptions}
                                    className="datatable-responsive p-datatable-sm"
                                    paginatorTemplate={paginatorLinks}
                                    currentPageReportTemplate={showingEntries}
                                    emptyMessage="No Packaging Data Found"
                                >
                                    {PackagingColumns.map((col, i) => {
                                        if (col.field === 'action') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={actionBodyTemplate}
                                                />
                                            );
                                        } else if (col.field === 'sr_no') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    header={col.header}
                                                    body={(_, { rowIndex }) => rowIndex + 1} />
                                            );
                                        } else if (col.field === 'crop') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={cropBodyTemplate}
                                                />
                                            );
                                        } else if (col.field === 'crop_division') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={cropDivisionBodyTemplate}
                                                />
                                            );
                                        } else if (col.field === 'lot_no') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={lotNoBodyTemplate}
                                                />
                                            );
                                        } else {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    sortable
                                                />
                                            );
                                        }
                                    })}
                                </DataTable>
                            </>
                        ) : (
                            <>
                                {/* Skeleton Data table */}
                                <DataTable value={Skeletonitems}>
                                    {PackagingColumns.map((col, i) => (
                                        <Column
                                            key={col.field}
                                            field={col.field}
                                            header={col.header}
                                            body={SkeletonbodyTemplate}
                                        />
                                    ))}
                                </DataTable>
                            </>
                        )}
                        {/* Datatable End */}

                        {/* Add Packing Dialog Start*/}
                        <Dialog
                            visible={packingDialog}
                            className="p-fluid"
                            onHide={() => hidePackingDailog()}
                            header="Add Packing Details"
                            footer={
                                <>
                                    <Button
                                        label="Cancel"
                                        icon="pi pi-times"
                                        className="p-button-text"
                                        onClick={() => hidePackingDailog()}
                                    />
                                    <Button
                                        label="Save"
                                        icon="pi pi-check"
                                        className="p-button-text"
                                        onClick={onSubmit}
                                        loading={loading}
                                    />
                                </>
                            }
                        >

                            <div className="formgrid grid">
                                <div className="field col-3">
                                    <p><b>Lot No.</b> : {!window.cn(viewProductData?.raw_material?.lot_no) ? viewProductData?.raw_material?.lot_no : null}</p>
                                </div>

                                <div className="field col-3">
                                    <p><b> Crop</b> : {!window.cn(viewProductData?.raw_material?.crop_obj?.name) ? viewProductData?.raw_material?.crop_obj?.name : null}</p>
                                </div>

                                <div className="field col-3">
                                    <p><b> Variety</b> : {!window.cn(viewProductData?.raw_material?.variety_obj?.name) ? viewProductData?.raw_material?.variety_obj?.name : null}</p>
                                </div>

                                <div className="field col-3">
                                    <p><b> Received Qty</b> : {!window.cn(viewProductData?.raw_material?.crop_qnty) ? viewProductData?.raw_material?.crop_qnty + " " + viewProductData?.raw_material?.weight_in : null}</p>
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col-3">
                                    <p><b>Procecced Quantity</b> : {!window.cn(viewProductData?.processed_qnty) ? viewProductData?.processed_qnty + " " + viewProductData?.weight_in : null}</p>
                                </div>

                                <div className="field col-3">
                                    <p><b>Remaining Quantity</b> : {!window.cn(viewProductData?.remaining_qnty) ? viewProductData?.remaining_qnty + " " + viewProductData?.weight_in : null}</p>
                                </div>

                                <div className="field col-3">
                                    <p><b> Remenant</b> : {!window.cn(viewProductData?.remenant) ? viewProductData?.remenant : null}</p>
                                </div>

                                <div className="field col-3">
                                    <p><b> Processed Loss</b> : {!window.cn(viewProductData?.losses) ? viewProductData?.losses : null}</p>
                                </div>
                            </div>

                            {getPackingDetailArrayUI()}

                            <div className="field">
                                <div className="field col">
                                    <Button
                                        style={{ width: 'fit-content' }}
                                        label="Add More"
                                        icon="pi pi-plus"
                                        className="p-button"
                                        onClick={handlePackingDetailArray}
                                    />
                                </div>
                            </div>
                            <small className="p-invalid-txt">You have remaining quantity :   {!window.cn(viewProductData?.remaining_qnty) ? viewProductData?.remaining_qnty + " " + viewProductData?.weight_in : null}</small><br/>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="packed_qnty">Quantity To Be Packed *</label>
                                    <InputText
                                        keyfilter="num"
                                        value={!window.cn(packingData.packed_qnty) ? packingData.packed_qnty : ""}
                                        name="packed_qnty"
                                        autoComplete="off"
                                        onChange={(e) => onInputChange(e, 'packed_qnty')}
                                        className={errors['packed_qnty'] && 'p-invalid'}
                                    />
                                    <small className="p-invalid-txt">{errors['losses']}</small>
                                </div>
                                <div className="field col">
                                    <label htmlFor="losses">Packing Loss *</label>
                                    <InputText
                                         keyfilter="num"
                                        value={!window.cn(packingData.losses) ? packingData.losses : ""}
                                        name="losses"
                                        autoComplete="off"
                                        onChange={(e) => onInputChange(e, 'losses')}
                                        className={errors['losses'] && 'p-invalid'}
                                    />
                                    <small className="p-invalid-txt">{errors['losses']}</small>
                                </div>
                                <div className="field col">
                                    <label htmlFor="quantity">Packed Qty</label>
                                    <InputText
                                        value={!window.cn(packingData.losses) && !window.cn(packingData?.packed_qnty) ? packingData?.packed_qnty - packingData.losses + viewProductData?.weight_in : ""}
                                        name="quantity"
                                        autoComplete="off"
                                        onChange={(e) => onInputChange(e, 'quantity')}
                                        className={errors['quantity'] && 'p-invalid'}
                                        disabled
                                    />
                                    <small className="p-invalid-txt">{errors['quantity']}</small>
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="label_from">Label From</label>
                                    <InputText
                                         keyfilter="num"
                                        value={!window.cn(packingData.label_from) ? packingData.label_from : ""}
                                        name="label_from"
                                        autoComplete="off"
                                        onChange={(e) => onInputChange(e, 'label_from')}
                                        className={errors['label_from'] && 'p-invalid'}
                                    />
                                    <small className="p-invalid-txt">{errors['label_from']}</small>
                                </div>
                                <div className="field col">
                                    <label htmlFor="label_to">Label To</label>
                                    <InputText
                                         keyfilter="num"
                                        value={!window.cn(packingData.label_to) ? packingData.label_to : ""}
                                        name="label_to"
                                        autoComplete="off"
                                        onChange={(e) => onInputChange(e, 'label_to')}
                                        className={errors['label_to'] && 'p-invalid'}
                                    />
                                    <small className="p-invalid-txt">{errors['label_to']}</small>
                                </div>
                                <div className="field col">
                                    <label htmlFor="missing_label">Missing Label</label>
                                    <InputText
                                        value={!window.cn(packingData.missing_label) ? packingData.missing_label : ""}
                                        name="missing_label"
                                        autoComplete="off"
                                        onChange={(e) => onInputChange(e, 'missing_label')}
                                        className={errors['missing_label'] && 'p-invalid'}
                                    />
                                    <small className="p-invalid-txt">{errors['missing_label']}</small>
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col-4">
                                    <label htmlFor="chemical_data">Select Chemical</label>
                                    <Dropdown
                                        value={chemicalData}
                                        onChange={(e) => onChemicalChange(e.value)}
                                        options={chemicalDropdown}
                                        optionLabel="name"
                                        name="chemical_data"
                                        placeholder="Select Chemical"
                                    ></Dropdown>
                                </div>
                                <div className="field col-4">
                                    <label htmlFor="thirum_chemical">Select Unit</label>
                                    <Dropdown
                                        value={chemicalWtData}
                                        onChange={(e) => setChemicalWtData(e.value)}
                                        options={chemicalWtDropdown}
                                        optionLabel="name"
                                        name="thirum_chemical"
                                        placeholder="Select Weight"
                                    ></Dropdown>
                                </div>
                                <div className="field col-4">
                                    <label htmlFor="chemical_quantity">Quantity</label>
                                    <InputText
                                        value={!window.cn(packingData.chemical_quantity) ? packingData.chemical_quantity : ""}
                                        name="chemical_quantity"
                                        autoComplete="off"
                                        onChange={(e) => onInputChange(e, 'chemical_quantity')}
                                    />
                                </div>
                            </div>
                            <div className="formgrid grid">
                            <div className="field col-4">
                                    <label htmlFor="outer_bag">Outer Bag</label>
                                    <Dropdown
                                        value={outerBag}
                                        onChange={(e) => setOuterBag(e.value)}
                                        options={outerBagDropdown}
                                        optionLabel="name"
                                        name="outer_bag"
                                    ></Dropdown>
                                </div>
                            </div>
                        </Dialog>
                        {/* Add Packing Dialog End*/}

                        {/* View Dialog start*/}
                        <Dialog
                            visible={viewProductDialog}
                            style={{ width: '650px' }}
                            header="View Product Data"
                            modal
                            footer
                            onHide={hideProductDialog}
                            className='crud-demo'
                        >
                            <div className="formgrid grid">
                                <div className="field col-4">
                                    <p><b>Lot No.</b> : {!window.cn(viewProductData?.raw_material?.lot_no) ? viewProductData?.raw_material?.lot_no : null}</p>
                                </div>

                                <div className="field col-4">
                                    <p><b> Crop</b> : {!window.cn(viewProductData?.raw_material?.crop_obj?.name) ? viewProductData?.raw_material?.crop_obj?.name : null}</p>
                                </div>

                                <div className="field col-4">
                                    <p><b> Variety</b> : {!window.cn(viewProductData?.raw_material?.variety_obj?.name) ? viewProductData?.raw_material?.variety_obj?.name : null}</p>
                                </div>
                            </div>

                            {/* <div className="formgrid grid">
                                <div className="field col-4">
                                    <p><b>Label From</b> : {!window.cn(viewProductData?.label_from) ? viewProductData?.label_from : null}</p>
                                </div>

                                <div className="field col-4">
                                    <p><b> Label To</b> : {!window.cn(viewProductData?.label_to) ? viewProductData?.label_to : null}</p>
                                </div>

                                <div className="field col-4">
                                    <p><b> Missing Label</b> : {!window.cn(viewProductData?.missing_label) ? viewProductData?.missing_label : null}</p>
                                </div>
                            </div> */}

                            {/* <div className="formgrid grid">
                                <div className="field col-4">
                                    <p><b>Packing Loss</b> : {!window.cn(viewProductData.losses) ? viewProductData.losses + viewProductData.weight_in : null}</p>
                                </div>

                                <div className="field col-4">
                                    <p><b> Packed Qty</b> : {!window.cn(viewProductData.quantity) ? viewProductData.quantity + viewProductData.weight_in : null}</p>
                                </div>

                                <div className="field col-4">
                                    <p><b>Data Added By</b> : {!window.cn(viewProductData.data_added_by_user) ? viewProductData.data_added_by_user : null}</p>
                                </div>
                            </div> */}

                            <DataTable
                                className="datatable-responsive"
                                value={!window.cn(viewProductData["packed_data"]) && viewProductData["packed_data"]}
                                rows={defaultRowOptions}
                                currentPageReportTemplate={showingEntries}
                                emptyMessage="No Crops Varieties Data Found"
                            >
                                {viewPackingColumns.map((col, i) => {
                                    if (col.field === 'sr_no') {
                                        return (
                                            <Column
                                                key={col.field}
                                                header={col.header}
                                                body={(_, { rowIndex }) => rowIndex + 1} />
                                        );
                                    } else if (col.field == "bag_weight") {
                                        return (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                body={bagWeightTemplate}
                                            />
                                        );
                                    } else if (col.field == "no_of_packets") {
                                        return (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                body={noOfPacketsTemplate}
                                            />
                                        );
                                    } else {
                                        return (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                            />
                                        );
                                    }
                                })}
                            </DataTable>

                        </Dialog>
                        {/* View Dialog End*/}

                        {
                            spinner && <Loader />
                        }

                    </div>
                </div>
            </div>
        </>
    );
};
